/**
 * @file Helper for Autoplay
 */

import { np__, p__, __ } from '@@/bits/intl'

const secondDurationLong = (number: number): string => {
  return np__(
    'Represents duration. Used for configuration. For example: 3 seconds',
    '%{number} second',
    '%{number} seconds',
    number,
    { number },
  )
}

const secondDurationShort = (number: number): string => {
  return p__('Represents duration. Used for configuration. For example: 3s', '%{number}s', { number })
}

const secondDurationText = (durationType: string): string => {
  if (durationType === 'auto') {
    return __('Auto')
  } else {
    return ''
  }
}

const AUTOPLAY_DURATION_TYPES = [1, 2, 3, 5, 15, 30, 60, 'auto'] as const
const AUTOPLAY_DURATIONS = AUTOPLAY_DURATION_TYPES.reduce((acc, duration) => {
  acc[duration] = {
    long: typeof duration === 'number' ? secondDurationLong(duration) : secondDurationText(duration),
    short: typeof duration === 'number' ? secondDurationShort(duration) : secondDurationText(duration),
  }
  return acc
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
}, {} as Record<typeof AUTOPLAY_DURATION_TYPES[number], { long: string; short: string }>)

type AutoplayDurationType = keyof typeof AUTOPLAY_DURATIONS

const BASE_SLIDE_DURATION_MS = 2000
const SLIDE_DURATION_PER_WORD_MS = 500
const SLIDE_DURATION_PER_ATTACHMENT_MS = 5000
const calculateAutoSlideDuration = (numWords: number, numAttachments: number): number => {
  return (
    BASE_SLIDE_DURATION_MS + SLIDE_DURATION_PER_WORD_MS * numWords + SLIDE_DURATION_PER_ATTACHMENT_MS * numAttachments
  )
}

const getAutoplayNotificationMessages = () =>
  ({
    stoppedAutoplaying: __('Autoplay stopped'),
    startedAutoplayingForTouchDevice: __('Autoplay started, press pause to stop'),
    startedAutoplayingForMouseDevice: __('Autoplay started, press any key to stop'),
  } as const)

export { AUTOPLAY_DURATION_TYPES, AUTOPLAY_DURATIONS, calculateAutoSlideDuration, getAutoplayNotificationMessages }
export type { AutoplayDurationType }
