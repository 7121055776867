// @file Types for slideshow

export enum PageType {
  Cover = 'cover',
  Section = 'section',
  Post = 'post',
}

export interface Page {
  pageType: PageType
  entityId?: string
}
